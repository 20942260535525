import React, { useState ,useEffect} from "react";
import Topbar from "../Sections/Topbar";
import Navbar from "../Sections/Navbar";
import Footer from "../Sections/Footer";
import { FaMapMarkerAlt } from "react-icons/fa";
import "../Styles/Contactus.css";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { ContactusApi } from "../APIS/apis";
import { useTranslation } from "react-i18next";

const Contactuspages = () => {
  const [input, setInput] = useState({
    name: "",
    email: "",
    message: "",
  });

  const { t } = useTranslation();

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInput({ ...input, [name]: value });
  };
  const handleSubmit = (e) => {
    let Relove_Token = localStorage.getItem("Relove_Token");

    e.preventDefault();
    ContactusApi(Relove_Token, input);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <>
      <Topbar />
      <Navbar />
      <div className="container">
        <div className="row">
          <div className=" col-lg-4">
            <div className="contact-office">
              <FaMapMarkerAlt />
              <h5>{t("Office")}</h5>
              <p>
                9th Floor, Unit No. 914, Wing C & D, Shiv Solitaire, Jogeshwari
                Station Road, Jogeshwari East, Mumbai - 400060
              </p>
            </div>
          </div>
          <div className=" col-lg-4">
            <div className="contact-office mt-3 mt-lg-0">
              <FaPhoneAlt />
              <h5>{t("PhoneNumber")}</h5>
              <p>+91 87799 58981</p>
            </div>
          </div>
          <div className=" col-lg-4">
            <div className="contact-office mt-3 mt-lg-0">
              <MdEmail />
              <h5>{t("Email")}</h5>
              <p>support@hasasidenim.com</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row d-flex justify-content-center mt-5 mb-3">
        <div className="contact-2">
          <div className="w-100">
            <h5 className="text-center">{t("ContactUs")}</h5>
            <form onSubmit={handleSubmit}>
              <div className="mb-3 ">
                <label className="form-label contactus-label">{t("Name")}</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={input.name}
                    onChange={handleInput}
                    minLength={4}
                  />
                </div>
              </div>
              <div className="mb-3 ">
                <label className="form-label contactus-label">{t("Email")}</label>
                <div>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    value={input.email}
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className="mb-3 ">
                <label className="form-label contactus-label">{t("Message")}</label>
                <div>
                  <textarea
                    type="email"
                    className="form-control"
                    rows="5"
                    name="message"
                    value={input.message}
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className="mb-3 d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-success contacts-button"
                >
                  {t("Submit")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contactuspages;
