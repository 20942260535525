import React, { useState } from "react";
import Box from "@mui/material/Box";
import {ErrorToaster} from "../Toaster"


import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import OtpInput from "react-otp-input";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { OtpverifyApi} from "../APIS/AuthApis";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #5759CB",
  p: 3,
};

const ModelOtp = ({ open1, setOpen1 }) => {
  const handleClose1 = () => setOpen1(false);
  const navigation = useNavigate();
  const { t } = useTranslation();

  const [otp, setOtp] = useState("");
const handleverify=(e)=>{
  e.preventDefault();

    // console.log(otp)
    let formdata={
        otp:otp,
    }
if(otp===""){
  ErrorToaster("Enter is valid otp")

}else{
    OtpverifyApi(formdata,navigation)}
    
}
  return (
    <>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modalpopup">
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <>
              <div className="d-flex justify-content-between">
                <h5>{t('EnterOtp')}</h5>
                <IoMdClose onClick={handleClose1} style={{fontSize:"25px"}} />
              </div>
              <div className="my-3">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderSeparator={<span className="m-3">-</span>}
                renderInput={(props) => <input {...props} style={{width:"40px",height:"40px"}} className="text-center"/>}
              />
              </div>
              <div className="mt-3 d-flex justify-content-end">
                <button className="btn btn-success" onClick={handleverify} style={{backgroundColor:"#5759CB",border:"#5759CB", color:"white"}}>{t('VerifyOtp')}</button>
              </div>
            </>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default ModelOtp;
