import React, { useState } from "react";
import Box from "@mui/material/Box";
import Code from "../Code";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { addAddressApi } from "../APIS/AuthApis";
import { FetchAddressApi } from "../APIS/apis";
import { IoMdClose } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { ErrorToaster } from "../Toaster";
import Citycode from "../CityCode";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,

  bgcolor: "background.paper",
  border: "2px solid  #5759CB",

  p: 4,
};

const NewAddress = ({ open, setOpen, setAddress, getSelectedLanguage }) => {
  const [selectCountryCode, setSelctCountryCode] = useState("+971");


  const { t } = useTranslation();

  const regExp = "^[0-9]+$"
  const handleClose = () => setOpen(false);
  const [input, setInput] = useState({
    name: "",
    city: "",
    state: "",
    street: "",
    landmark: "",
    code: "",
    mobile: "",
    phone_code: selectCountryCode,
    phone_country: selectCountryCode,
    address_type: "Home",
    default: 1,
  });

  const handleinput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setInput({ ...input, [name]: value });
  };
  const handleSubmit = (e) => {

    let Relove_Token = localStorage.getItem("Relove_Token");
    let formdata = {
      language: getSelectedLanguage,
    };
    e.preventDefault();
    if (input.name === '' || input.address_type === '' || input.city === '' || input.landmark === '' || input.mobile === '') {
      ErrorToaster("All field is requried")
    } else if (input?.code?.length >= '7' || input?.code === '') {
      ErrorToaster("Code is Less than 7 digit")

    }
    else if (input?.mobile?.length <= '7' || input?.mobile?.length >= '13') {
      ErrorToaster("Mobile Number 8 to 12")
    }
    else {
      addAddressApi(Relove_Token, input, FetchAddressApi, formdata, (callback) => {
        setAddress(callback?.data);
      });
      handleClose()
    }




  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modalpopup">
          <Typography id="modal-modal-description" sx={{ mt: 2 }}  >
            <div className="d-flex justify-content-between">
              <h5 style={{ marginBottom: "20px" }}>{t('AddAddress')}</h5>
              <IoMdClose onClick={handleClose} fontSize={25} />
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="form-label">{t('Name')}</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={input.name}
                    onChange={handleinput}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">{t('MobileNumber')}</label>
                <div className="row">
                  <div className="col-4">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={selectCountryCode}
                      onChange={(e) => setSelctCountryCode(e.target.value)}
                    >
                      <option selected>{selectCountryCode}</option>
                      {Code?.map((ele, i) => {
                        return (
                          <option key={i} value={ele.dial_code}>
                            {ele.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-8">
                    <input
                      type="Number"
                      className="form-control"
                      name="mobile"
                      value={input.mobile}
                      onChange={handleinput}



                    />
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">{t('State')}</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    name="state"
                    value={input.state}
                    onChange={handleinput}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">{t('City')}</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    name="city"
                    value={input.city}
                    onChange={handleinput}
                  />
                </div>
                {/* <div>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={input.city}
                    name='city'
                    onChange={handleinput}
                  >
                    <option selected>{input.city}</option>
                    {Citycode?.map((ele, i) => {
                      return (
                        <option key={i} value={ele.code}>
                          {ele.name}
                        </option>
                      );
                    })}
                  </select>

                </div> */}
              </div>
              <div className="mb-3">
                <label className="form-label">{t('Street')}</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    name="street"
                    value={input.street}
                    onChange={handleinput}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">{t('LandMark')}</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    name="landmark"
                    value={input.landmark}
                    onChange={handleinput}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">{t('PinCodeAddress')}</label>
                <div>
                  <input
                    type="Number"
                    className="form-control"
                    name="code"
                    value={input.code}
                    onChange={handleinput}


                  />
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">{t('AddressType')}</label>
                <div className="d-flex">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="address_type"
                      id="flexRadioDefault1"
                      onClick={
                        handleinput
                      }
                      checked={input.address_type === "Home" ? true : false}
                      value={"Home"}
                    />
                    <label class="form-check-label" for="flexRadioDefault1">
                      {t('AddressHome')}
                    </label>
                  </div>
                  <div class="form-check ms-3">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="address_type"
                      id="flexRadioDefault2"
                      onClick={
                        handleinput
                      }
                      checked={input.address_type === "Office" ? true : false}
                      value={"Office"}
                    />
                    <label class="form-check-label" for="flexRadioDefault2">
                      {t('AddressOffice')}
                    </label>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-success"
                  type="submit"
                  style={{ backgroundColor: "#5759CB", border: "none" }}
                >
                  {t('AddAddress')}
                </button>
              </div>
            </form>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default NewAddress;
