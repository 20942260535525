import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../../src/Styles/slider.css";

import { IoMdStar } from "react-icons/io";
import { IoMdStarHalf } from "react-icons/io";
import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";

function SliclSlider() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <FaAngleRight
        className="FulfillmentSlider_Arrowr_right"
        onClick={onClick}
      />
    );
  };
  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <FaAngleLeft
        className="FulfillmentSlider_Arrowr_left"
        onClick={onClick}
      />
    );
  };
  return (
    <>
      <div className="slick_slider py-5">
        <div className="container-fluid">
          <Slider
            {...settings}
            nextArrow={<CustomNextArrow />}
            prevArrow={<CustomPrevArrow />}
          >
            <div className="testimonial_main">
              <h3>TESTIMONIALS ONE</h3>
              <p>
                Release of Letraset sheets containing Lorem Ipsum passages, and
                more recently Lorem Ipsum passages.Release of Letraset sheets
                containing Lorem Ipsum passages, and more recently Lorem Ipsum
                passages.and more recently Lorem Ipsum passages.
              </p>
              <div className="testimonial_rating">
                <IoMdStar className="rating_star" />
                <IoMdStar className="rating_star" />
                <IoMdStar className="rating_star" />
                <IoMdStar className="rating_star" />
                <IoMdStarHalf className="rating_star" />
              </div>
              <h5>Rehen Lockman</h5>
              <h6>Customer</h6>
            </div>
            <div className="testimonial_main">
              <h3>TESTIMONIALS TWO</h3>
              <p>
                Release of Letraset sheets containing Lorem Ipsum passages, and
                more recently Lorem Ipsum passages.Release of Letraset sheets
                containing Lorem Ipsum passages, and more recently Lorem Ipsum
                passages.and more recently Lorem Ipsum passages.
              </p>
              <div className="testimonial_rating">
                <IoMdStar className="rating_star" />
                <IoMdStar className="rating_star" />
                <IoMdStar className="rating_star" />
                <IoMdStar className="rating_star" />
                <IoMdStarHalf className="rating_star" />
              </div>
              <h5>Rehen Lockman</h5>
              <h6>Customer</h6>
            </div>
            <div className="testimonial_main">
              <h3>TESTIMONIALS THREE</h3>
              <p>
                Release of Letraset sheets containing Lorem Ipsum passages, and
                more recently Lorem Ipsum passages.Release of Letraset sheets
                containing Lorem Ipsum passages, and more recently Lorem Ipsum
                passages.and more recently Lorem Ipsum passages.
              </p>
              <div className="testimonial_rating">
                <IoMdStar className="rating_star" />
                <IoMdStar className="rating_star" />
                <IoMdStar className="rating_star" />
                <IoMdStar className="rating_star" />
                <IoMdStarHalf className="rating_star" />
              </div>
              <h5>Rehen Lockman</h5>
              <h6>Customer</h6>
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
}

export default SliclSlider;
