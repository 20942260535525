import React, { useState } from "react";
import "./../Styles/topsale.css";

function AllProductHeading({
  allProducts,
  handleClickHeader,
  selectItem,
  setSelectItem,
}) {
  return (
    <div className="topsale">
      <div className="container">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8 heading-topsale text-center">
            <h3>OUR COLLECTIONS</h3>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="topsale-tab-list d-flex justify-content-center">
            <ul>
              <li
                onClick={() => handleClickHeader("All")}
                className={selectItem === "All" ? "select-topsale-item" : ""}
              >
                All
              </li>
              {allProducts?.map((item, i) => {
                return (
                  item?.products?.length > 0 && <li
                    key={i}
                    onClick={() => handleClickHeader(item.id)}
                    className={
                      selectItem === item.id ? "select-topsale-item" : ""
                    }
                  >
                    {item.collectionName}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllProductHeading;
