import React,{useEffect} from 'react';
import Topbar from '../Sections/Topbar'
import { useTranslation } from "react-i18next";


import Footer from '../Sections/Footer';
import Navbar from '../Sections/Navbar';

const ReturnPolicy = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
 <>
   <Topbar/>
   <Navbar/>
   <div className='' style={{border:"1px solid #5759CB"}}></div>
   <div className='container'>
  
    <div className='mt-3'>
    <p><strong>{t('Return&refund')}</strong></p>
<p>{t('OurReturnlast')}</p>
<p>{t('ThankYouforReturn')}</p>
<p>{t('ReturnReason')} <a href="https://www.termsfeed.com/blog/sample-return-policy-ecommerce-stores/"></a>.</p>
<p>{t('FollwingTermsAre')}</p>
<p><strong>{t('ReturnInterpretation')}</strong></p>
<p><strong>{t('Interpretation')}</strong></p>
<p>{t('TheWordsofReturn')}</p>
<p><strong>{t('Returndefinitions')}</strong></p>
<p>{t('ReturnPurpose')}</p>
<ul>
<li>
<p><strong>"{t('Company')}"</strong> {t('CompanyReffered')}</p>
</li>
<li>
<p><strong>"{t('Goodsreturn')}"</strong> {t('Goodreffer')}</p>
</li>
<li>
<p><strong>"{t('OrdersRturn')}"</strong> {t('Orderrequeset')}</p>
</li>
<li>
<p><strong>"{t('ServiceReturn')}"</strong> {t('ReturnInterpretation')}</p>
</li>
<li>
<p><strong>"{t('WebsiteReturn')}"</strong> {t('WebsiteReferto')}</p>
</li>
<li>
<p><strong>"{t('YouReturn')}"</strong> {t('Youmeansthe')}</p>
</li>
</ul>
<p><strong>{t('OrderCancellationRight')}</strong></p>
<p>{t('Youareentitled')}</p>
<p>{t('DeadlineReturn')}</p>
<p>{t('OrderexerciseYour')}</p>
<ul>
<li>{t('VisitingReturn')}</li>
<li>{t('Sendingusemailreturn')}</li>
</ul>
<p>{t('Returnreimburse')}</p>
<p><strong>{t('ReturnConditions')}</strong></p>
<p>{t('InorderforReturn')}</p>
<ul>
<li>{t('Returnpurched')}</li>
<li>{t('theGoodsarepackaging')}</li>
</ul>
<p>{t('ReturnGoodscannot')}</p>
<ul>
<li>{t('Returnmade')}</li>
<li>{t('returnnaturewhich')}</li>
<li>{t('returnsuitablewhich')}</li>
<li>{t('returnDeliverywhich')}</li>
</ul>
<p>{t('Retrunresrve')}</p>
<p><strong>{t('ReturnReturing')}</strong></p>
<p>{t('ReturnResponsiblecost')}</p>

<p>{t('Returnresponibledamage')}</p>
<p><strong>{t('Giftsreturn')}</strong></p>
<p>{t('Giftsmarked')}</p>
<p>{t('Giftsnot')}</p>
<p><strong>{t('ReturnContact')}</strong></p>
<p>{t('returnquestion')}</p>
<ul>


</ul>
<p></p>
    </div>
   </div>
   <Footer/>
</> 
  )
}

export default ReturnPolicy