import React from "react";
import "../Styles/Sale.css";

function Sale() {
  return (
    <>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-6">
            <div className="sale1">
              <h3>Men Wear Sale</h3>
              <h5>
                Up to <span>30%</span> OFF
              </h5>
              <p>
                Lorem Ipsum is simply dummy text<br></br> typesetting industry.
              </p>
              <button>Shop Now</button>
            </div>
          </div>
          <div className="col-md-6">
            <div className="sale1 sale2">
              <h3>Men Wear Sale</h3>
              <h5>
                Up to <span>30%</span> OFF
              </h5>
              <p>
                Lorem Ipsum is simply dummy text<br></br> typesetting industry.
              </p>
              <button>Shop Now</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sale;
