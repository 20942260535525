import React, { useEffect, useState, useRef } from "react";
import { SlUser } from "react-icons/sl";
import { BsHeart } from "react-icons/bs";
import { BsBagCheck } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { setUserAuthentication } from "../Redux/actions";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Badge from "@mui/material/Badge";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./../Styles/navbar.css";
import { Searchproduacts } from "../APIS/apis";
import { IoMenu } from "react-icons/io5";
import { IoHomeOutline } from "react-icons/io5";
import { BiCategory } from "react-icons/bi";
import LoginModal from "../Modals/LoginModal";
import { IoIosClose } from "react-icons/io";
import { getParentCategory } from "../APIS/apis";
import { RiArrowDropDownLine } from "react-icons/ri";

const theme = createTheme({
  palette: {
    primary: {
      main: "#A26D3F",
    },
  },
});

function Navbar({
  getUserAuthenticationRedux,
  setUserAuthenticationRedux,
  getCartLengthRedux,
  getUserDetailRedux,
  getWishLengthRedux,
  getSelectedLanguage,
}) {
  const { t } = useTranslation();
  let Relove_Token = localStorage.getItem("Relove_Token");
  const searchRef = useRef();
  const routeName = window.location.pathname.split("/")[1];
  const navigation = useNavigate();
  const [searchInput, setSearchInput] = useState([]);
  const [inputValue, setinputvalue] = useState("");
  let language = localStorage.getItem("language");

  const [data, setData] = useState([]);
  const [catData, setCatData] = useState([]);



  useEffect(() => {
    setUserAuthenticationRedux(
      getUserAuthenticationRedux ? getUserAuthenticationRedux : Relove_Token
    );
  }, [getCartLengthRedux]);

  const searchIteam = (searchvalue) => {
    setinputvalue(searchvalue);
    Searchproduacts(
      getSelectedLanguage,
      searchvalue,
      getUserDetailRedux?.id || "",
      (data) => {
        setSearchInput(data.data.products);
      }
    );
  };
  const [show, setShow] = useState(false);
  const [catshow, setCatshow] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const handletoogelbar = () => {
    navigation("/profile");
  };

  useEffect(() => {
    getParentCategory(language, (response) => {
      setData(response);
      setCatData(response);
    });
  }, [language]);

  useEffect(() => {
    const closeDropdown = (e) => {
      if (!searchRef.current.contains(e.target)) {
        setinputvalue("");
      }
    };
    document.body.addEventListener("click", closeDropdown);
    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);

  return (
    <>
      <LoginModal
        setShowLoginModal={setShowLoginModal}
        showLoginModal={showLoginModal}
      />
      {/* <div
        className={`navbar_main ${routeName === "product" ? "shadow-sm " : ""}`}
      >
        
      </div> */}
      <div className="container-fluid px-lg-4 px-md-3">
        <div className="row py-3 d-md-flex  justify-content-between">
          <div className="col-4 col-md-4 col-lg-2">
            <div className="navbar-logos">
              <img
                onClick={() => navigation("/")}
                src="/img/logo2.jpg"
                alt="logo-img"
              />
            </div>
          </div>

          <div className="col-lg-5 d-lg-flex align-items-center justify-content-center d-none">
            <div className="navbar-menu-items">
              <ul>
                <li className="li_one" onClick={() => navigation("/")}>
                  {t("HOME")}
                </li>
                {data.length > 0
                  ? data.map((item, i) => {
                    return (
                      <ul
                        onClick={() =>
                          navigation(`/category/${item.id}/${item.slug}`)
                        }
                        key={i}
                      >
                        {item.name.toUpperCase() === 'WOMEN' || item.name.toUpperCase() === 'MEN' ? <li className="li_one">{item.name.toUpperCase()}</li> : ''}
                      </ul>
                    );
                  })
                  : <>
                    <li className="li_one">MEN</li>
                    <li className="li_one">WOMEN</li>
                  </>}

                {
                  catData?.length >= 0 && <li
                    className="d-flex align-items-center catDropdown"
                    onMouseEnter={() => setCatshow(true)}
                    onMouseLeave={() => setCatshow(false)}
                  >
                    {t("CATEGORIES")}{" "}
                    <RiArrowDropDownLine style={{ fontSize: "20px" }} />
                    {catshow ? (
                      <div className="catDropdown-content">
                        {catData.length > 0
                          ? catData.map((item, i) => {
                            return (
                              <React.Fragment key={i}>
                                {item.name.toUpperCase() !== 'WOMEN' && item.name.toUpperCase() !== 'MEN' ? <p
                                  className="catDropdownhover text-dark text-start m-0"
                                  onClick={() =>
                                    navigation(
                                      `/category/${item.id}/${item.slug}`
                                    )
                                  }
                                >
                                  {item.name.toUpperCase()}
                                </p> : ''}
                                {i < catData.length - 1 && (
                                  <hr className="m-0" style={{ color: 'grey', bottomWidth: "2px" }} />
                                )}
                              </React.Fragment>
                            );
                          })
                          : ""}
                      </div>
                    ) : (
                      ""
                    )}
                  </li>
                }
              </ul>
            </div>
          </div>

          <div className="col-md-5 col-lg-3  d-md-flex d-none align-items-center navbar-search-section">
            <div
              className="navbar-search-field"
              style={{ position: "relative" }}
              ref={searchRef}
            >
              <FiSearch />
              <input
                type="text"
                name=""
                id=""
                value={inputValue}
                onChange={(e) => searchIteam(e.target.value)}
                placeholder={t("searchforproduct")}
              />
              {inputValue.length > 0 ? (
                <div className="search-bar-result">
                  {inputValue.length > 0
                    ? searchInput?.map((cur, i) => {
                      return (
                        <div key={i} className="text-center">
                          <p
                            className="bg-white"
                            style={{ width: "100%", cursor: "pointer" }}
                            onClick={() => {
                              navigation(`/product/${cur.id}`);
                            }}
                          >
                            {cur.name}
                          </p>
                        </div>
                      );
                    })
                    : ""}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div
            className="col-md-2 d-flex d-lg-none align-items-center justify-content-end navbar-toggle-section"
            style={{ position: "relative" }}
          >
            {show === true ? (
              <IoIosClose
                style={{ fontSize: "40px", cursor: "pointer" }}
                onClick={() => setShow(false)}
              />
            ) : (
              <IoMenu
                style={{ fontSize: "40px", cursor: "pointer" }}
                onClick={() => setShow(!show)}
              />
            )}
          </div>

          {show ? (
            <div
              className="my-1 toggelmenuheading"
              style={{ position: "absolute" }}
            >
              <div className="d-flex mt-3 justify-content-end">
                <IoIosClose
                  style={{ fontSize: "35px" }}
                  onClick={() => setShow(false)}
                />
              </div>
              <div
                className="navbar-search-field mt-3  d-sm-flex d-md-none"
                style={{ position: "relative", width: "100%" }}
              >
                <FiSearch style={{ color: "black" }} />
                <input
                  type="text"
                  name=""
                  id=""
                  value={inputValue}
                  onChange={(e) => searchIteam(e.target.value)}
                  placeholder={t("searchforproduct")}
                />
                <div
                  className="search-bar-result"
                  style={{ top: "40px", fontSize: "11px" }}
                >
                  {inputValue.length > 0
                    ? searchInput?.map((cur, i) => {
                      return (
                        <div key={i} className="text-center">
                          <p
                            className="bg-white mt-1 text-dark"
                            style={{ width: "100%", cursor: "pointer" }}
                            onClick={() => {
                              navigation(`/product/${cur.id}`); setShow(false);
                            }}
                          >
                            {cur.name}
                          </p>
                        </div>
                      );
                    })
                    : ""}
                </div>
              </div>

              <div
                className="my-1 mt-3 d-flex align-items-center toggelactive"
                onClick={() => { navigation("/"); setShow(false) }}
              >
                <IoHomeOutline
                  style={{
                    color: "white",
                    marginRight: "5px",
                    cursor: "pointer",
                  }}
                />
                <p style={{ cursor: "pointer" }} className="m-0">{t("HOME")}</p>
              </div>

              {data.length > 0
                ? data.map((item, i) => {
                  return (
                    <div
                      onClick={() => {
                        navigation(`/category/${item.id}/${item.slug}`); setShow(false)
                      }
                      }
                      key={i}
                      className="d-flex align-items-center mt-3"
                    >
                      <BiCategory
                        style={{
                          color: "white",
                          marginRight: "5px",
                          marginTop: "4px",
                          cursor: "pointer",
                        }}
                      />
                      <p style={{ cursor: "pointer" }} className="m-0">
                        {item.name.toUpperCase()}
                      </p>
                    </div>
                  );
                })
                : ""}


              <div className="mt-3">
                {Relove_Token ? (
                  <div className="d-flex align-items-center " onClick={() => { handletoogelbar(); setShow(false) }}>
                    <SlUser
                      style={{
                        color: "white",
                        marginRight: "5px",
                        marginTop: "4px",
                        cursor: "pointer",
                      }}
                    />
                    <p style={{ cursor: "pointer" }} className="m-0">{t("PROFILE")}</p>
                  </div>
                ) : (
                  <div
                    className="d-flex align-items-center"
                    onClick={() => { navigation("/login"); setShow(false) }}
                  >
                    <SlUser
                      style={{
                        color: "white",
                        marginRight: "5px",
                        marginTop: "4px",
                      }}
                    />
                    <p style={{ cursor: "pointer" }} className="m-0">LOGIN</p>
                  </div>
                )}
              </div>
              <div className="mt-3">
                <div
                  className="d-flex align-items-center"
                  onClick={() => {
                    let Relove_Token = localStorage.getItem("Relove_Token");

                    if (!Relove_Token) {
                      setShowLoginModal(true);
                      setShow(false)
                    } else {
                      navigation("/wishlist");
                      setShow(false);
                    }
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Badge
                      theme={theme}
                      badgeContent={getWishLengthRedux}
                      color="primary"
                    >
                      <BsHeart
                        style={{
                          color: "white",
                          marginRight: "5px",
                          marginTop: "4px",
                        }}
                      />
                    </Badge>
                  </ThemeProvider>

                  <p style={{ cursor: "pointer" }} className="m-0">{t("WISHLIST")}</p>
                </div>
              </div>
              <div className="mt-3">
                <div
                  onClick={() => {
                    let Relove_Token = localStorage.getItem("Relove_Token");
                    if (!Relove_Token) {
                      setShowLoginModal(true);
                      setShow(false)
                    } else {
                      navigation("/cart");
                      setShow(false);
                    }
                  }}
                  className="d-flex align-items-center"
                >
                  <ThemeProvider theme={theme}>
                    <Badge
                      theme={theme}
                      badgeContent={getCartLengthRedux}
                      color="primary"
                    >
                      <BsBagCheck
                        style={{
                          color: "white",
                          marginRight: "5px",
                          marginTop: "4px",
                        }}
                      />
                    </Badge>
                  </ThemeProvider>
                  <p style={{ cursor: "pointer" }} className="m-0">{t("BAG")}</p>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* </div> */}

          <div className="col-md-2 d-lg-flex align-items-center justify-content-around d-none">
            <div className="navbar-last-items">
              {Relove_Token ? (
                <div
                  className="navbar-last-item-2"
                  onClick={() => navigation("/profile")}
                >
                  <SlUser />
                </div>
              ) : (
                <div
                  className="navbar-last-item-2"
                  onClick={() => navigation("/login")}
                >
                  <SlUser />
                </div>
              )}

              <div
                className="navbar-last-item-2"
                onClick={() => {
                  let Relove_Token = localStorage.getItem("Relove_Token");

                  if (!Relove_Token) {
                    setShowLoginModal(true);
                  } else {
                    navigation("/wishlist");
                  }
                }}
              >
                <ThemeProvider theme={theme}>
                  <Badge
                    theme={theme}
                    badgeContent={getWishLengthRedux}
                    color="primary"
                  >
                    <BsHeart />
                  </Badge>
                </ThemeProvider>
              </div>
              <div
                onClick={() => {
                  let Relove_Token = localStorage.getItem("Relove_Token");

                  if (!Relove_Token) {
                    setShowLoginModal(true);
                  } else {
                    navigation("/cart");
                  }
                }}
                className="navbar-last-item-2"
              >
                <ThemeProvider theme={theme}>
                  <Badge
                    theme={theme}
                    badgeContent={getCartLengthRedux}
                    color="primary"
                  >
                    <BsBagCheck />
                  </Badge>
                </ThemeProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getUserAuthenticationRedux: state.userAuthentication.data,
    getCartLengthRedux: state.length.length,
    getWishLengthRedux: state.wishlength.length,
    getUserDetailRedux: state.user.data,
    getSelectedLanguage: state.language.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserAuthenticationRedux: (parameter) => {
      dispatch(setUserAuthentication(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
