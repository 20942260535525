import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Stepper } from "react-form-stepper";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: '50%',
  bgcolor: "background.paper",
  border: "2px solid #807a7a",
  p: 2,
};

const TrackModel = ({ open, setOpen, trackData }) => {
  const handleClose = () => setOpen(false);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalpopup"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }} >
            <h4 style={{ fontSize: '25px' }}>Origin</h4>
            <p>{trackData && trackData.Origin}</p>
            <h4 style={{ fontSize: '25px' }}>Destination</h4>
            <p>{trackData && trackData.Destination}</p>
            <h4 style={{ fontSize: '25px' }}>Status</h4>
            <p>{trackData && trackData.Status.Status}</p>
            <h4 style={{ fontSize: '25px' }}>Current Location</h4>
            <p>{trackData && trackData.Status.StatusLocation}</p>
            {/* <Stepper
              steps={[
                { label: "Order Confirmed" },
                { label: "Shipped" },
                { label: "Out for delivery" },
                { label: "Out for Delivered" },

              ]}
             
              activeStep={1}
             
            /> */}
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default TrackModel;
