import React from "react";
import "./../Styles/Iconcards.css";

function IconCards() {
  return (
    <>
      <div className="container iconcard_section mb-md-5">
        <div className="row">
          <div className="col-lg-3 col-md-6 cardicon_main">
            <div className="cardicon_img">
              <img src="\img\Package.png" alt="" />
            </div>
            <div className="cardicon_text">
              <h5>Fastest Delivery</h5>
              <p>Delivery in 24/H</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 cardicon_main">
            <div className="cardicon_img">
              <img src="\img\Trophy.png" alt="" />
            </div>
            <div className="cardicon_text">
              <h5>24 Hours Return</h5>
              <p>100% money-back guarantee</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 cardicon_main">
            <div className="cardicon_img">
              <img src="\img\CreditCard.png" alt="" />
            </div>
            <div className="cardicon_text">
              <h5>Secure Payment</h5>
              <p>Your money is safe</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 cardicon_main">
            <div className="cardicon_img">
              <img src="\img\Headphones.png" alt="" />
            </div>
            <div className="cardicon_text">
              <h5>Support 24/7</h5>
              <p>Live contact/message</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default IconCards;
