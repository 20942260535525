import React from "react";
import "./../Styles/cards.css";

function Cards() {
  return (
    <>
      <div className="container mb-5 p-md-0">
        <div className="row ">
          <div className="col-md-6 ">
            <div className="card_1">
              <h2>
                <span>₹99</span> Membership <br></br>
                Fees
              </h2>
              <h5>Upto 10 orders shipping free </h5>
            </div>
          </div>
          <div className="col-md-6 ">
            <div className="card_2">
              <h2>
                Vote for new <br></br>
                designs
              </h2>
              <h5>Upto 10 orders shipping free </h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cards;
