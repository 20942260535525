import React, { useEffect, useState } from "react";
import { AiOutlineHeart } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { getAllProductsHomeApi } from "../APIS/apis";
import "./../Styles/similierproduct.css";
import { useTranslation } from "react-i18next";

function SimilierProducts({ setUpdateProductPage, updateProductPage }) {
  const navigation = useNavigate();
  const { t } = useTranslation();
  let language = localStorage.getItem("language")
  const [products, setProducts] = useState([]);
  useEffect(() => {
    getAllProductsHomeApi(language, (data) => {
      setProducts([...data.homeProduct[1]?.products, ...data.homeProduct[2]?.products]);
    });
  }, []);

  return (
    <div className="similierproduct">
      <div className="container">
        <div className="similierproduct-main">
          <h3>{t('SimilierProducts')}</h3>

          <ul>
            {products &&
              products?.slice(0, 10).map((item, i) => {

                return (
                  <li key={i} onClick={() => {
                    navigation(`/product/${item.id}`);
                    setUpdateProductPage(!updateProductPage)
                  }} >
                    <div className="text-center">
                      {/* <div className="text-end"> <AiOutlineHeart/></div> */}
                      {/* <span className="text-center">{item?.offerDiscount}% {t('OFF')}</span> */}
                    </div>
                    {/* <h6>{t('PureCotton')}</h6> */}
                    <img src={item.featured_image} alt='similiarImage'></img>
                    <h5 className="text-start" style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}>{item?.name}</h5>
                    <div className="d-flex justify-content-between align-items-center">
                      <p>
                        {t('₹')}{item?.sale_price} {" "}
                        <span className="discount-price">{t('₹')}({item?.base_price})</span>
                      </p>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SimilierProducts;
