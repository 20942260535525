import React from "react";
import { FaInstagram } from "react-icons/fa";
import { FiFacebook } from "react-icons/fi";
import { FiLinkedin } from "react-icons/fi";
import "./../Styles/footer.css";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigation = useNavigate();

  return (
    <>
      <div className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
              <div className="footer-first-section">
                <img src="\img\footer_logo.png" alt="" />
                <p>
                  Hasasi is a globally renowned brand known for its high-quality
                  denim clothing and accessories. In India, the brand offers a
                  wide range of clothing for men, women.
                </p>
              </div>
            </div>
            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 company_info">
              <div className="footer-first-section">
                <ul>
                  <li>COMPANY INFO</li>
                  <li
                    onClick={() => {
                      navigation("/aboutus");
                      window.scroll(0, 0);
                    }}
                  >
                    About Company
                  </li>
                  <li
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  >
                    Social Responsibility
                  </li>

                  <li
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  >
                    Afiliate
                  </li>
                  <li
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  >
                    Fashion Blogger
                  </li>
                  <li
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  >
                    {" "}
                    Careers
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6">
              <div className="footer-first-section">
                <ul>
                  <li>Customer</li>
                  <li
                    onClick={() => {
                      navigation("/returnpolicy");
                      window.scroll(0, 0);
                    }}
                  >
                    Return Policy
                  </li>
                  <li>How to track</li>
                  <li>Size Guide</li>
                </ul>
              </div>
            </div>
            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6">
              <div className="footer-first-section">
                <ul>
                  <li>Customercare</li>
                  <li
                    onClick={() => {
                      navigation("/contact");
                      window.scroll(0, 0);
                    }}
                  >
                    Contact Us
                  </li>
                  <li
                    onClick={() => {
                      navigation("/termsuse");
                      window.scroll(0, 0);
                    }}
                  >
                    Terms And Conditions
                  </li>
                  <li>Bonus Point</li>

                  <li>Recall Notices</li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 col-12">
              <div className="footer-second-section">
                <h3>SIGN UP TO OUR NEWSLETTER</h3>
                <section class="newsletter">
                  <div className="container-md">
                    <div className="row">
                      <div className="col-sm-12 p-0">
                        <div className="content">
                          <div className="input-group">
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Enter your email"
                            />
                            <span className="input-group-btn">
                              <button className="btn" type="submit">
                                Subscribe Now
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <p>
                  By clicking on the SUBSCRIBE button you are agreeing to our
                  Privacy & Cookie Policies
                </p>
              </div>
            </div>
          </div>
          <div className="row social_row">
            <div className="col-md-3 social_main">
              <h3>CONNECT US</h3>
              <div className="footer-social-icon">
                <FaInstagram />
                <FiFacebook />
                <FiLinkedin />
              </div>
            </div>
            <div className="col-md-6"></div>
            <div className="col-md-3 payment_main">
              <h3>WE ACCEPT</h3>
              <div className="payment_icon">
                <img src="/img/american express.png" alt="" />
                <img src="/img/bitcoin.png" alt="" />
                <img src="/img/paypal.png" alt="" />
                <img src="/img/CB.png" alt="" />
                <img src="/img/Dmca.png" alt="" />
                <img src="/img/maestro.png" alt="" />
                <img src="/img/visa.png" alt="" />
                <img src="/img/mastercard.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container-fluid">
          <div className="row privacy_row">
            <div className="col-md-5 col-sm-8 copyrite">
              <p>© 2024 Copyright - HASASI DENIM WEAR PVT LTD.</p>
            </div>

            <div className="col-md-4 col-sm-4 privacy_main">
              <div className="d-flex justify-content-evenly privacy">
                <p>Privacy & Policy</p>
                <p>Terms & Condition</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
