import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import Topbar from "../Sections/Topbar";
import Navbar from "../Sections/Navbar";
import Footer from "../Sections/Footer";
import { getSingleProduct } from "../APIS/apis";
import SliderImage from "react-zoom-slider";
import {
  AiFillStar,
  AiOutlinePlus,
  AiOutlineMinus,
  AiOutlineHeart,
  AiFillHeart,
} from "react-icons/ai";
import SimilierProducts from "./SimilierProducts";
import { ErrorToaster } from "../Toaster";
import Spinner from "react-bootstrap/Spinner";
import { connect } from "react-redux";
import { GoArrowRight } from "react-icons/go";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  SingleProductVariationChildApi,
  SingleProductVariationApi,
  AddToCartApi,
  FetchCartApi,
} from "../APIS/cartApis";
import {
  AddWishlistApi,
  FetchWishlistApi,
  RemoveWishlistApi,
} from "../APIS/WishlistApis";
import { setCartLength, setWishLength } from "../Redux/actions";
import LoginModal from "../Modals/LoginModal";
import "./../Styles/product.css";
import AddReview from "../Pages/AddReview";
import Review from "../Pages/Review";
import { checkAvailabilityApi } from "../APIS/apis";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
// import genuine from '/'


function ProductPage({
  getSelectedLanguage,
  setCartLengthRedux,
  setWishLengthRedux,
  getCartLengthRedux,
  getWishLengthRedux,
}) {
  const pathID = window.location.pathname.split("/")[2];
  const navigation = useNavigate();
  const { t } = useTranslation();
  const fullScreenRef = useRef();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [imagesData, setimagesData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [selectRootAttributes, setSelectRootAttributes] = useState(null);
  const [updateProductPage, setUpdateProductPage] = useState(false);
  const [updateProductGetCartLength, setUpdateProductGetCartLength] =
    useState(false);

  const [variationSlider, setVariationSlider] = useState([]);
  const [selectedFirstVariation, setSelectedFirstVariation] = useState(false);
  const [selectedSecondVariation, setSelectedSecondVariation] = useState(false);

  const [basePrice, setBasePrice] = useState(null);
  const [salePrice, setSalePrice] = useState(null);
  const [variationId, setvariationId] = useState(null);
  const [averageRating, setAverageRating] = useState();
  const [productQuatity, setProductQantity] = useState(1);
  const [totalProductQuatity, setTotalProductQantity] = useState(null);

  const [allWishtDate, setAllWishDate] = useState([]);

  const [show, setShow] = useState(false);

  const [pincode, setPincode] = useState('');
  const [deliveryAvailable, setDeliverAvailable] = useState('')
  const [isScrolled, setIsScrolled] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [])

  useLayoutEffect(() => {
    getSingleProduct(pathID, getSelectedLanguage, (data) => {
      let imageData = data.data.product[0]?.imagesGet;
      setProductData(data.data.product);
      setimagesData(imageData);
    });

    window.scrollTo(0, 0);
  }, [updateProductPage, getSelectedLanguage, pathID]);

  // const images = [
  //   {
  //     original: "https://picsum.photos/id/1018/1000/600/",
  //     thumbnail: "https://picsum.photos/id/1018/250/150/",
  //   },
  //   {
  //     original: "https://picsum.photos/id/1015/1000/600/",
  //     thumbnail: "https://picsum.photos/id/1015/250/150/",
  //   },
  //   {
  //     original: "https://picsum.photos/id/1019/1000/600/",
  //     thumbnail: "https://picsum.photos/id/1019/250/150/",
  //   },
  // ];

  const images = imagesData.map((imgUrl) => ({
    original: imgUrl,
    thumbnail: imgUrl,
  }));

  let imagesForSlider = [];
  // for (let i = 0; i < imagesData.length; i++) {
  //   imagesForSlider.push({ image: imagesData[i] });
  // }

  const handleAddToCart = (productId, variationId, quantity) => {
    let Relove_Token = localStorage.getItem("Relove_Token");
    let fromdata = {
      productId: productId,
      variationId: variationId,
      quantity: quantity,
    };
    if (!Relove_Token) {
      setShowLoginModal(true);
    } else if (!selectRootAttributes) {
      ErrorToaster(`Please Select ${productData[0]?.rootAttributes?.attributeName}`);
    } else if (!selectedSecondVariation) {
      ErrorToaster(`Please Select ${productData[0]?.subAttributes[0]?.attributeName}`);
    } else {
      AddToCartApi(
        Relove_Token,
        fromdata,
        updateProductPage,
        setUpdateProductPage
      );
      setShow(true);
    }
  };

  useEffect(() => {
    let Relove_Token = localStorage.getItem("Relove_Token");
    let formdata = {
      language: getSelectedLanguage,
    };
    FetchCartApi(Relove_Token, formdata, (callbackData) => {
      setCartLengthRedux(callbackData?.data?.cart_items?.length);
    });
    FetchWishlistApi(Relove_Token, formdata, (callbackData) => {
      setAllWishDate(callbackData?.data);
      setWishLengthRedux(callbackData);
    });
  }, [
    updateProductGetCartLength,
    updateProductPage,
    getSelectedLanguage,
    setWishLengthRedux,
  ]);

  const handleWisthlist = (productId) => {
    let Relove_Token = localStorage.getItem("Relove_Token");
    let formdata = {
      language: getSelectedLanguage,
      productId: productId,
    };
    AddWishlistApi(
      Relove_Token,
      formdata,
      updateProductPage,
      setUpdateProductPage,
      FetchWishlistApi,
      setWishLengthRedux
    );
    // const value = allWishtDate?.filter((iteam) => {
    //   return iteam.product_id === productId;
    // });
    // if (value[0]?.product_id === productId) {
    //   RemoveWishlistApi(
    //     Relove_Token,
    //     formdata,
    //     updateProductPage,
    //     setUpdateProductPage,
    //     FetchWishlistApi,
    //     setWishLengthRedux
    //   );
    // } else {
    //   AddWishlistApi(
    //     Relove_Token,
    //     formdata,
    //     updateProductPage,
    //     setUpdateProductPage,
    //     FetchWishlistApi,
    //     setWishLengthRedux
    //   );
    // }
  };

  const handlebuy = (producetId, variationId, productQuatity) => {
    let Relove_Token = localStorage.getItem("Relove_Token");
    let fromdata = {
      productId: producetId,
      variationId: variationId,
      quantity: productQuatity,
    };
    if (!Relove_Token) {
      setShowLoginModal(true);
    } else if (!selectRootAttributes) {
      ErrorToaster(`Please Select ${productData[0]?.rootAttributes?.attributeName}`);
    } else if (!selectedSecondVariation) {
      ErrorToaster(`Please Select ${productData[0]?.subAttributes[0]?.attributeName}`);
    } else {
      AddToCartApi(
        Relove_Token,
        fromdata,
        updateProductPage,
        setUpdateProductPage
      );
      navigation("/cart");
    }
  };

  const handlePincode = () => {
    if (!pincode) {
      ErrorToaster('Enter your pincode');
      return;
    }
    const data = {
      pincode: pincode
    }
    checkAvailabilityApi(data, (callback) => {
      if (callback === true) {
        setDeliverAvailable('yes')
      } else {
        setDeliverAvailable('no')
      }
    })
  }

  const showFullScreen = () => {
    fullScreenRef.current.toggleFullScreen();
  };

  return (
    <>
      <LoginModal
        setShowLoginModal={setShowLoginModal}
        showLoginModal={showLoginModal}
      />
      <div className={`top-bars ${isScrolled ? "scrolled" : ""}`}>
        <Topbar />
        <Navbar />
      </div>
      <div className="container products_main" style={{ minHeight: "500px" }}>
        {productData?.length === 0 ? (
          <div className="product_page_loader">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">{t("LodingProduct")}</span>
            </Spinner>
          </div>
        ) : (
          <div className="row mt-5">
            <div className="col-lg-6">
              <div style={{ position: "sticky", top: 150 }}>
                <ImageGallery ref={fullScreenRef} items={variationSlider.length === 0
                  ? images
                  : variationSlider} thumbnailPosition={"left"} showFullscreenButton={false} showPlayButton={false} lazyLoad={true} slideOnThumbnailOver={true} onClick={() => {
                    showFullScreen()
                  }} />
              </div>
            </div>


            {productData &&
              productData?.map((item, i) => {
                return (
                  <div
                    key={i}
                    className="col-lg-6  product_right"
                    id="product-right-id"
                  >
                    <div className="product-right-side-information mt-5 mt-lg-0">
                      <span className="product-right-side-information-title-top">
                        HASASI JEANS
                      </span>
                      <h5 className="product-right-side-information-title mt-2 mb-2">
                        {item?.name}
                      </h5>

                      <h6 className="product-right-side-information-price mb-3">
                        <span className="product-right-side-information-baseprice">
                          {t("₹")}
                          {basePrice ? basePrice : item.base_price}
                        </span>
                        <span className="product-right-side-information-saleprice">
                          {t("₹")} {salePrice ? salePrice : item.sale_price}{" "}
                        </span>
                        <span className="product-right-side-information-title-tax">
                          Price inclusive of all taxes.
                        </span>
                      </h6>

                      <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
                      <img src="/img/genuine.png" alt='genuine' style={{ height: '80px' }}></img>
                      <img src="/img/quality.png" alt='genuine' style={{ height: '80px' }}></img>
                    </div>

                    <div className="selected-size-main py-4">
                      <div className="select-size">
                        <p className="fw-bold mb-2">
                          {item?.rootAttributes?.attributeName}
                        </p>
                      </div>
                      <div className="products-color" style={{ overflowX: "scroll" }}>
                        <ul className="p-0 m-0 d-flex gap-3 row">
                          {item?.rootAttributes?.childData.map((rootAtt, i) => {
                            return (
                              <li
                                key={i}
                                className={`col-1 ${selectedFirstVariation === rootAtt?.childId
                                  ? "selectRootAttributesClass"
                                  : ""
                                  }`}
                                onClick={() => {
                                  if (
                                    rootAtt.childId !== selectedFirstVariation
                                  ) {
                                    if (variationSlider.length > 0) {
                                      setVariationSlider([]);
                                    }

                                    let userToken =
                                      localStorage.getItem("Relove_Token");
                                    let formdata = {
                                      productId: parseInt(item.id),
                                      childId: parseInt(rootAtt?.childId),
                                      variationId: parseInt(
                                        rootAtt?.variationId
                                      ),
                                      language: getSelectedLanguage,
                                    };
                                    SingleProductVariationChildApi(
                                      userToken,
                                      formdata,
                                      (callbackData) => {
                                        let variationImage =
                                          callbackData.variationData.images;
                                        setSelectedFirstVariation(
                                          rootAtt?.childId
                                        );
                                        setSelectRootAttributes(
                                          callbackData.variationData
                                            .variationInfo[0]
                                        );
                                        for (
                                          let i = 0;
                                          i < variationImage.length;
                                          i++
                                        ) {
                                          variationSlider.push({
                                            original: variationImage[i],
                                            thumbnail: variationImage[i],
                                          });
                                        }
                                      }
                                    );
                                  }
                                }}
                              >
                                {rootAtt?.childName}
                              </li>
                            );
                          })}
                        </ul>
                      </div>

                      {selectRootAttributes ? (
                        <>
                          <div className="select-size">
                            <p className="fw-bold mb-2 mt-1">
                              {selectRootAttributes?.attributeName}
                            </p>
                          </div>
                          <div className="d-flex">
                            {selectRootAttributes?.childData.map(
                              (subAtt, i) => {
                                return (
                                  <div key={i} className="products-sizes ">
                                    <ul className="p-0 m-0 d-flex gap-3 row">
                                      <li
                                        className={`col-2 ${selectedSecondVariation === subAtt?.childId
                                          ? "selectRootAttributesClass"
                                          : ""
                                          }`}

                                        onClick={() => {
                                          let userToken =
                                            localStorage.getItem(
                                              "Relove_Token"
                                            );
                                          let formdata = {
                                            productId: parseInt(item.id),
                                            childId: [
                                              selectedFirstVariation,
                                              subAtt?.childId,
                                            ],
                                            language: getSelectedLanguage,
                                          };
                                          setSelectedSecondVariation(
                                            subAtt?.childId
                                          );
                                          SingleProductVariationApi(
                                            userToken,
                                            formdata,
                                            (callbackData) => {
                                              setVariationSlider([]);
                                              setvariationId(
                                                callbackData?.variation[0]?.id
                                              );
                                              setTotalProductQantity(
                                                callbackData?.variation[0]
                                                  ?.quantity
                                              );
                                              setSalePrice(
                                                callbackData?.variation[0]
                                                  ?.sale_price
                                              );
                                              setBasePrice(
                                                callbackData?.variation[0]
                                                  ?.base_price
                                              );
                                              let variationImage =
                                                callbackData.variation[0]
                                                  .images;

                                              for (
                                                let i = 0;
                                                i < variationImage.length;
                                                i++
                                              ) {
                                                variationSlider.push({
                                                  original: variationImage[i],
                                                  thumbnail: variationImage[i],
                                                });
                                              }
                                            }
                                          );
                                        }}
                                      >
                                        {subAtt?.childName}
                                      </li>
                                    </ul>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </>

                      ) : null}

                      {selectedSecondVariation ? (
                        <div className="cart_degree mt-2">
                          <AiOutlineMinus
                            onClick={() => {
                              if (productQuatity === 1) {
                              } else {
                                setProductQantity(productQuatity - 1);
                              }
                            }}
                          />
                          <span>{productQuatity}</span>
                          <AiOutlinePlus
                            onClick={() => {
                              if (totalProductQuatity <= productQuatity) {
                              } else {
                                setProductQantity(productQuatity + 1);
                              }
                            }}
                          />
                        </div>
                      ) : null}

                      <div
                        className="products-buttons mt-4"
                      >
                        <button
                          className='add-cart-button'
                          onClick={() =>
                            handleAddToCart(
                              item?.id,
                              variationId,
                              productQuatity
                            )

                          }
                        >
                          <span>Add to cart</span>
                        </button>

                        <button
                          className='add-cart-wishlist mt-2'
                          onClick={() => {
                            let Relove_Token = localStorage.getItem("Relove_Token");
                            if (!Relove_Token) {
                              setShowLoginModal(true);
                            } else {
                              handleWisthlist(productData[0]?.id);
                            }
                          }}
                        >
                          <span>Add to wishlist</span>
                        </button>


                        {show ? (
                          <div className="addcartlength-item-show">
                            <div
                              className="d-flex justify-content-center align-items-center"
                              onClick={() => navigation("/cart")}
                            >
                              <p className="mb-0">{t("ViewCart")}</p>
                              <span className="me-1 ms-1">
                                ({getCartLengthRedux})
                              </span>
                              <span>
                                <GoArrowRight />
                              </span>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="delivery-option py-4">
                      <h6>Check availability at</h6>
                      <div className="d-flex align-items-center">
                        <input type="text" placeholder="Enter your pincode" onChange={(e) => {
                          setDeliverAvailable('')
                          setPincode(e.target.value)
                        }} />
                        <span onClick={() => handlePincode()}>Check</span>
                      </div>
                      <ul>

                        {
                          pincode && deliveryAvailable ? deliveryAvailable === 'yes' ? <li><p style={{ backgroundColor: "#00c04b", color: '#ffffff' }}>Service available at the provided pin code</p></li> : <li><p style={{ backgroundColor: "#FF474D", color: '#ffffff' }}>Service not available at the provided pin code</p></li> : ''
                        }

                      </ul>

                    </div>

                    <div className="about-product">
                      <h6>About Hasasi</h6>
                      <div className="d-flex align-items-center ">
                        <img src="/img/logo.png" alt="img" />
                        <p>Established in the year 2017 at Mumbai, (Maharashtra, India), we “Hasasi Denims Wear” are Proprietorship Firm and acknowledged among the noteworthy Manufacturer and Wholesaler of the best quality Mens Jeans, Denim Jeans, Trendy Jeans, Casual Jeans and Regular Fit Jeans.</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            <div className="container">
              <div className="row d-flex justify-content-center">
                <AddReview
                  id={productData.length > 0 ? productData[0]?.id : ""}
                />
                <Review
                  pid={productData[0]?.id}
                  averageRating={averageRating}
                  setAverageRating={setAverageRating}
                />
              </div>
            </div>
            <SimilierProducts
              updateProductPage={updateProductPage}
              setUpdateProductPage={setUpdateProductPage}
            />
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getSelectedLanguage: state.language.language,
    getCartLengthRedux: state.length.length,
    getWishLengthRedux: state.wishlength.length,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCartLengthRedux: (parameter) => {
      dispatch(setCartLength(parameter));
    },
    setWishLengthRedux: (parameter) => {
      dispatch(setWishLength(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage);
