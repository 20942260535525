import React from "react";
import "./../Styles/bannermiddle.css";
import { IoMdPlay } from "react-icons/io";

function BannerMiddle() {
  return (
    <>
      <div className="container-fluid bannermiddle_main p-0">
        <div className="row">
          <div className="col-md-8">
            <div className="left-section">
              <div className="c">
                <h5>EXCEPTIONAL DESIGNER</h5>
                <h1>Clothing Men Fashion</h1>
                <p>
                  Lorem Ipsum is simply dummy text typesetting industry.Lorem
                  Ipsum is simply
                </p>
                <button>Shop Now</button>
              </div>
            </div>
          </div>
          <div className="col-md-4 ">
            <div className="right_section">
              <img src="/img/banner_right.png" alt="" />
              <div className="videoplay">
                <IoMdPlay className="videoicon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BannerMiddle;
