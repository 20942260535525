import React, { useState, useEffect } from "react";
import { getParentCategory } from "../APIS/apis";
import { useNavigate } from "react-router-dom";
import "./../Styles/parentcategory.css";

function ParentCategory({ getSelectedLanguage }) {
  let language = localStorage.getItem("language");
  const navigation = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    getParentCategory(language, (response) => {
      setData(response);
    });
  }, [language]);

  return (
    <>
      <div style={{ backgroundColor: "rgba(87, 89, 203, 0.1)" }} >
        <div className="container mb-5 py-5">
          <h3 className="text-center mb-4" style={{ fontWeight: "bold" }}>POPULAR CATEGORIES</h3>
          <div className="row gy-2">
            {
              data.map((item, i) => {
                return (
                  <div className="col-xl-3  col-md-6" key={i}
                    onClick={() =>
                      navigation(`/category/${item.id}/${item.slug}`)
                    }>
                    <div className="category-card">
                      <img src={item.image} alt="Bracelets" />
                      <div className="category-name-overlay">
                        <h3>{item.name}</h3>
                      </div>
                      <div className="category-overlay">
                        <h3>Shop Now</h3>
                      </div>
                    </div>
                  </div>
                )
              })
            }

          </div>
        </div>

      </div>
      {/* <div style={{ backgroundColor: "rgba(87, 89, 203, 0.1)" }}>
        <div class=" parentcategory container text-center mb-5 py-5">
          <div class="row justify-content-center">
            <h3>CATEGORIES</h3>
            {
              data.map((item, i) => {
                return (
                  <div class="col-lg-3 col-md-6"
                    key={i}
                    onClick={() =>
                      navigation(`/category/${item.id}/${item.slug}`)
                    }>
                    <div class="image-container">
                      <img src={item.image} class="img-fluid rounded-circle" alt="Shorts" />
                      <div class="overlay-text">{item.name}</div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div> */}

      {/* <div className="parentcategory">
        <div className="container">
          <div className="row py-5 justify-content-center circle_row">
            <h3>CATEGORIES</h3>
            {data.map((item, i) => {
              return (
                <div
                  onClick={() =>
                    navigation(`/category/${item.id}/${item.slug}`)
                  }
                  key={i}
                  className="parentcategory-main circle"
                >
                  <img src={item.image} alt="" className="parent-category-img" />
                  <p>{item.name}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div> */}
    </>
  );
}

export default ParentCategory;
