import React, { useState } from "react";
import Box from "@mui/material/Box";
import Code from "../Code";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,

  bgcolor: "background.paper",
  border: "2px solid #000",

  p: 4,
};

const Paymentmodel = ({ open5, setOpen5 }) => {
  const [selectCountryCode, setSelctCountryCode] = useState("india");

  const handleClose5 = () => setOpen5(false);
  // const [input, setInput] = useState({
  //   email: "",
  //   tel: "",
  //   name: "",
  //   selectCountryCode: "",
  //   expiry:"",
  //   cvv:"",
  // });
  // const handleinput = (e) => {
  //   const name = e.target.name;
  //   const value = e.target.value;
  //   setInput({ ...input, [name]: value });
  // };
  const handleSubmit = (e) => {
    
    e.preventDefault();


    // console.log(input)
    // console.log(stripe)
  };


  return (
    <>
      <Modal
        open={open5}
        onClose={handleClose5}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modalpopup" >
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {/* <h1>Pay With Card</h1>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="form-label">Email</label>
                <div>
                  <input
                    type="Email"
                    className="form-control"
                    name="email"
                    value={input.email}
                    onChange={handleinput}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">Card Information</label>
                <div>
                  <input
                    type="tel"
                    className="form-control"
                    name="tel"
                    value={input.tel}
                    onChange={handleinput}
                  />
                </div>
              </div>
              <div className="mb-3 d-flex">
                <div className="col-6">
                    <label className="form-label">Expiry Date</label>
                    <div>
                    <input
                    type="text"
                    className="form-control"
                    name="expiry"
                    value={input.expiry}
                    onChange={handleinput}
                  />
                    </div>
                </div>
                <div className="col-6 ms-1">
                <label className="form-label">Cvv</label>
                    <div>
                    <input
                    type="text"
                    className="form-control"
                    name="cvv"
                    value={input.cvv}
                    onChange={handleinput}
                  />
                    </div>
                </div>

              </div>
              <div className="mb-3">
                <label className="form-label">Name On Card</label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={input.name}
                    onChange={handleinput}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">Country Of Region</label>

                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={selectCountryCode}
                  onChange={(e) => setSelctCountryCode(e.target.value)}
                >
                  <option selected>{selectCountryCode}</option>
                  {Code?.map((ele, i) => {
                    return (
                      <option key={i} value={ele.name}>
                        {ele.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-success"
                  type="submit"
                  style={{ backgroundColor: "#5759CB", border: "none" }}
                >
                  Pay
                </button>
              </div>
            </form> */}
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default Paymentmodel;
