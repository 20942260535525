import React from "react";
import "./../Styles/fashionsale.css";

function FashionSale() {

  return (
    <div className="fashiondale">
      <div className="container">
        <div className="row fashion_row">
          <div className="col-md-6  fashionsale-background">
            <img src="/img/voucher1.png" alt="" />
          </div>
          <div className="col-md-6 fashionsale-background">
            <img src="/img/voucher2.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FashionSale;
