import React, { useEffect, useState } from "react";
import Navbar from "../Sections/Navbar";
import Topbar from "../Sections/Topbar";
import Banner from "../Sections/Banner";
import Footer from "../Sections/Footer";
import Filters from "./Filters";
import { useNavigate, NavLink } from "react-router-dom";
import { fetchCategoryProducts } from "../APIS/apis";
import "../Styles/categorypage.css";
import { useTranslation } from "react-i18next";
import { IoFilter } from "react-icons/io5";

function CategoryPage() {
  const navigation = useNavigate();
  const { t } = useTranslation();
  const id = window.location.pathname.split("/")[2];

  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [categoryProductsLength, setCategoryProductLength] = useState([]);
  const [bannerImage, setBannerImage] = useState('')
  const [isScrolled, setIsScrolled] = useState(false);
  const [sizes, setSizes] = useState([])
  const [colors, setColors] = useState([])
  const [minprice, setMinprice] = useState(0);
  const [maxprice, setMaxprice] = useState("5000+");
  const [size, setSize] = useState([]);
  const [color, setColor] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const recordsPerPage = 20;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const npage = Math.ceil(categoryProductsLength / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id])

  useEffect(() => {
    let formdata = {
      page: currentPage,
      language: "en",
      orderBy: "id",
      categoryId: id,
      filters: [
        {
          type: "price",
          min: minprice,
          max: maxprice === "5000+" ? 500000 : maxprice
        },
        {
          type: "color",
          value: color
        },
        {
          type: "size",
          value: size
        }
      ]
    }

    fetchCategoryProducts(formdata, (data) => {
      setBannerImage(data?.categoryBanner[0].bannerImage)
      setProducts(data?.data?.categoryProducts);
      setCategoryProductLength(data?.data?.length);
      setSizes(data?.size);
      setColors(data?.colour);
    });
  }, [currentPage, maxprice, minprice, id, color, size]);


  const perPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const changeCpage = (n) => {
    setCurrentPage(n);
    window.scroll(0, 0);
  };

  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleClose = () => setShowFilters(false);

  return (
    <>
      <Filters size={size}
        setSize={setSize}
        color={color}
        setColor={setColor}
        minprice={minprice}
        setMinprice={setMinprice}
        maxprice={maxprice}
        setMaxprice={setMaxprice}
        sizes={sizes}
        colors={colors}
        show={showFilters}
        handleClose={handleClose}
      />
      <div className={`top-bars ${isScrolled ? "scrolled" : ""}`}>
        <Topbar />
        <Navbar />
      </div>
      <Banner bannerImage={bannerImage} />
      <div className="container py-5">
        <button class="filter-button" onClick={() => setShowFilters(true)}>
          <span class="filter-icon"><IoFilter /></span>
          Filters
        </button>
        <div className="row">
          {/* <div className="col-xxl-2 col-lg-3 d-md-block d-none">
            <Filters
              size={size}
              setSize={setSize}
              color={color}
              setColor={setColor}
              minprice={minprice}
              setMinprice={setMinprice}
              maxprice={maxprice}
              setMaxprice={setMaxprice}
              sizes={sizes}
              colors={colors}
            />
          </div> */}
          <div className="col-xxl-12 col-lg-12">
            <div className="category-product-main">
              <ul>
                {products.length > 0 ? products?.map((item, i) => {
                  return (
                    <li
                      // onMouseEnter={() => setShowHeartIcon(i)}
                      key={i}
                      onClick={() => {
                        navigation(`/product/${item.id}`);
                      }}
                    >
                      <div
                        className="text-center"
                      // style={{
                      //   backgroundImage: `url(${item.featured_image})`,
                      //   backgroundRepeat:"no-repeat",
                      //   backgroundSize:"cover",
                      //   backgroundPosition:"center"
                      // }}
                      >
                        {/* <div
                            className={`text-end ${showHeartIcon === i ? "d-block" : "d-none"
                              } `}
                          >
                            {" "}
                            <AiOutlineHeart />
                          </div> */}
                        {/* <span className="text-center">
                            {item?.offerDiscount}% {t("OFF")}
                          </span> */}
                      </div>
                      {/* <h6>{t("PureCotton")}</h6> */}
                      <img src={item.featured_image} alt="" style={{ width: "100%", height: '350px' }}></img>
                      <div style={{ backfroundColor: "rgb(255, 255, 255)", padding: "10px" }}>
                        <h5
                          className={`text-start`}
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {item?.name_en}
                        </h5>
                        <div className="d-flex justify-content-between align-items-center">
                          <p>
                            {t("₹")} {item?.sale_price} {" "}
                            <span className="discount-price-category">
                              {t("₹")}({item?.base_price})
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="category-on-hover-card">
                        {/* <h6>{t("PureCotton")}</h6> */}
                        <h5
                          className={`text-center`}
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {item?.name_en}
                        </h5>
                        <div className="d-flex justify-content-center align-items-center">
                          <p className="text-center">
                            {t("₹")}{item?.sale_price} {" "}
                            <span className="discount-price-category text-center ">
                              {t("₹")}({item?.base_price})
                            </span>
                          </p>
                        </div>
                        {/* <button>{t("AddToCart")}</button> */}
                      </div>
                    </li>
                  );
                }) : <p style={{ width: "100%", textAlign: "center", fontSize: "22px", fontWeight: "bold" }}>No products found</p>}
              </ul>
            </div>
          </div>
          {
            categoryProductsLength > 20 && <nav>
              <ul className="pagination d-flex justify-content-end">
                <li className="page-item">
                  <NavLink
                    to=""
                    className={`page-link${currentPage === 1 ? "back" : ""}`}
                    onClick={perPage}
                  >
                    {currentPage === 1 ? "" : <>{t("PrevPage")}</>}{" "}
                  </NavLink>
                </li>
                {numbers.map((n, i) => (
                  <li
                    className={`page-item ${currentPage === n ? "active" : ""}`}
                    key={i}
                  >
                    <NavLink
                      to=""
                      className="page-link"
                      onClick={() => changeCpage(n)}
                    >
                      {n}
                    </NavLink>
                  </li>
                ))}
                <li className="page-item">
                  <NavLink
                    to=""
                    className={`page-link${currentPage === numbers.length ? "next" : ""
                      }`}
                    onClick={nextPage}
                  >
                    {currentPage === numbers.length ? "" : <>{t("NextPage")}</>}
                  </NavLink>
                </li>
              </ul>
            </nav>
          }
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CategoryPage;
