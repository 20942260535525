import React, { useLayoutEffect, useState, useEffect } from "react";
import Topbar from "../Sections/Topbar";
import Navbar from "../Sections/Navbar";
import ParentCategory from "../Sections/ParentCategory";
import Banner from "../Sections/Banner";
import FashionSale from "../Sections/FashionSale";
import AllProductHeading from "../Sections/AllProductHeading";
import AllProducts from "../Sections/AllProducts";
import BannerMiddle from "../Sections/BannerMiddle";
import TodayPopularSale from "../Sections/TodayPopularSale";
import Footer from "../Sections/Footer";
import { connect } from "react-redux";
import { getAllProductsHomeApi } from "../APIS/apis";
import {
  AddWishlistApi,
  FetchWishlistApi,
  RemoveWishlistApi,
} from "../APIS/WishlistApis";
import { setAllProducts, setWishLength } from "../Redux/actions";
import IconCards from "../Sections/IconCards";
import Cards from "../Sections/Cards";
import SliclSlider from "../Sections/SliclSlider";
import Sale from "../Sections/Sale";
import { getParentCategory } from "../APIS/apis";


function Home({
  setAllProductsRedux,
  getAllProductsRedux,
  getSelectedLanguage,
  getWishLengthRedux,
  setUpdateProductSetWishLength,
  getUserAuthenticationRedux,
}) {
  const [selectItem, setSelectItem] = useState("All");
  const [allHeadings, setAllHeadings] = useState([]);
  const [updateProductPage, setUpdateProductPage] = useState(false);
  const [bannerImage, setBannerImage] = useState('')
  const [data, setData] = useState([]);
  const [isScrolled, setIsScrolled] = useState(false);
  const language = localStorage.getItem("language");


  useEffect(() => {
    fetchAllProducts();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [])


  const fetchAllProducts = () => {
    getAllProductsHomeApi(language, (data) => {
      setBannerImage(data.bnnerImage);
      setAllProductsRedux(data.homeProduct);
      setAllHeadings(data.homeProduct);
    });
  };

  const getparentcategory = () => {
    getParentCategory(language, (response) => {
      setData(response);
    });
  }


  const handleClickHeader = (productID) => {
    if (productID === "All") {
      fetchAllProducts();
      setSelectItem(productID);
    } else {
      setSelectItem(productID);
      getAllProductsHomeApi(language, (data) => {
        data.homeProduct.forEach((ele, i) => {
          if (ele.id === productID) {
            console.log("data", [ele]);
            setAllProductsRedux([ele]);
          }
        });
      });
    }
  };
  const handleWisthlist = (productId) => {
    let Relove_Token = localStorage.getItem("Relove_Token");
    let formdata = {
      language: getSelectedLanguage,
      productId: productId,
    };

    AddWishlistApi(
      Relove_Token,
      formdata,
      updateProductPage,
      setUpdateProductPage,
      FetchWishlistApi,
      setUpdateProductSetWishLength
    );
  };

  return (
    <div>
      <div className={`top-bars ${isScrolled ? "scrolled" : ""}`}>
        <Topbar />
        <Navbar />
      </div>
      <Banner bannerImage={bannerImage} />
      <ParentCategory getSelectedLanguage={getSelectedLanguage} />
      <FashionSale />
      <AllProductHeading
        allProducts={allHeadings}
        handleClickHeader={handleClickHeader}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
      />
      <AllProducts
        allProducts={getAllProductsRedux}
        handleWisthlist={handleWisthlist}
        updateProductPage={updateProductPage}
      />
      <Sale />
      <BannerMiddle />
      {/* <TodayPopularSale data={data} /> */}
      <SliclSlider />
      <IconCards />
      <Cards />
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    getAllProductsRedux: state.allProducts.products,
    getSelectedLanguage: state.language.language,
    getWishLengthRedux: state.wishlength.length,
    getUserAuthenticationRedux: state.userAuthentication.data,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setAllProductsRedux: (parameter) => {
      dispatch(setAllProducts(parameter));
    },
    setUpdateProductSetWishLength: (parameter) => {
      dispatch(setWishLength(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
